import React from 'react'

import Footer from './Footer'
import avatar from '../assets/images/gavin.jpeg'

class Header extends React.Component {
    render() {
        return (
            <header id="header">
                <div className="inner">
                    <a href="#" className="image avatar"><img src={avatar} alt="avatar" /></a>
                    <p><strong>Gavin</strong> graduated with a Bachelor's degree in Commerce at the University of the Witwatersrand, South Africa in 2004. He then obtained his Master of Business Administration (MBA) from DePaul University, U.S.A in 2011.
                    He has since been operating family business full-time.
                    </p><br/>
                </div>
                <Footer />
            </header>
        )
    }
}

export default Header
